var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("div", { staticClass: "fontBold fontS25 padd10 x-fc" }, [
        _c("div", [
          _c("span", [_vm._v("物料名称:")]),
          _c("span", { staticClass: "annotateBlue" }, [
            _vm._v(_vm._s(_vm.billInfo.goodsName)),
          ]),
        ]),
        _c("div", { staticClass: "marL20" }, [
          _c("span", [_vm._v("分货数:")]),
          _c("span", { staticClass: "annotateBlue" }, [
            _vm._v(_vm._s(_vm.billInfo.unitQty)),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-fc" },
        [
          _c("TablePage", {
            ref: "tablePage",
            staticStyle: { width: "40%" },
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options1,
              callback: function ($$v) {
                _vm.options1 = $$v
              },
              expression: "options1",
            },
          }),
          _c("TablePage", {
            ref: "tablePage1",
            staticStyle: { width: "60%" },
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options2,
              callback: function ($$v) {
                _vm.options2 = $$v
              },
              expression: "options2",
            },
          }),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage2",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }