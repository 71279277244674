<template>
  <div class="wrap">
    <cardTitleCom cardTitle="搜索">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="thisMonth"
            v-model="queryParams"
            :getList="getList"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh" class="searchCriteria">
            <div class="marR20">
              <span class="fS14MR10">单据编号</span>
              <el-input
                class="inputWidth marR15"
                placeholder="全部"
                v-model="queryParams.billNo"
              />
              <span class="fS14MR10">退料原因</span>
              <el-input
                class="inputWidth marR15"
                placeholder="全部"
                v-model="queryParams.backCause"
              />
              <span class="fS14MR10">退料部门</span>
              <selectFrameUp
                class="selectFrameUp"
                v-model="queryParams.deptName"
                @getPitchId="getDiyselectId($event, 'deptId')"
                displayContent="Name"
                :tableData="deptList"
                :normalizer="normalizerdept"
                :router="'/system/dept'"
                @click="opendept = true"
              />
              <span class="fS14MR10">退料仓库</span>
              <el-select
                class="inputWidth"
                v-model="queryParams.inStoreId"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="item in storeData"
                  :key="item.storeId"
                  :label="item.storeName"
                  :value="item.storeId"
                />
              </el-select>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="单据信息">
      <template slot="cardContent">
        <div class="tableContent">
          <div class="tableBtn">
            <div>
              <el-button
                class="marR10"
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                >新增</el-button
              >
              <el-dropdown
                class="marR10"
                split-button
                type="success"
                icon="el-icon-circle-check"
                :disabled="multiple"
                @click="auditBill('审核')"
                @command="auditBill"
                size="mini"
              >
                审核
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="反审核">反审核</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                type="danger"
                icon="el-icon-delete"
                :disabled="multiple"
                @click="auditBill('删除')"
                size="mini"
                >删除
              </el-button>
            </div>

            <!-- 右边盒子 -->
            <div>
              <el-button icon="el-icon-download" size="mini">导入</el-button>
              <el-button icon="el-icon-upload2" size="mini">导出</el-button>
              <el-button icon="el-icon-printer" size="mini">打印</el-button>
              <el-button
                size="mini"
                class="marRBtn"
                icon="el-icon-refresh"
                @click="test($event)"
                >刷新
              </el-button>
            </div>
          </div>
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            @row-click="handleRowClick"
            tooltip-effect="dark"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            max-height="590"
            height="590"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="单据编号"
              align="center"
              prop="billNo"
              width="180"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.billNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              label="单据日期"
              align="center"
              prop="billDate"
              width="110"
            />
            <el-table-column
              label="单据状态"
              align="center"
              prop="billStatusName"
            />
            <el-table-column
              label="退料部门"
              align="center"
              prop="deptName"
              width="120"
            />
            <el-table-column
              label="退料仓库"
              align="center"
              prop="inStoreName"
              width="120"
            />
            <el-table-column label="数量" align="center" prop="billQty" />
            <el-table-column label="金额" align="center" prop="billMoney" />
            <el-table-column label="备注" align="center" prop="billRemark" />
            <el-table-column
              label="创建人"
              align="center"
              prop="createBy"
              width="110"
            />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="155"
            />
            <el-table-column
              label="修改人"
              align="center"
              prop="updateBy"
              width="110"
            />
            <el-table-column
              label="修改时间"
              align="center"
              prop="updateTime"
              width="155"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
    <!-- 选择部门信息框 -->
    <selectdept
      :opendept.sync="opendept"
      :isSelectDeptRadio="true"
      @deptListCom="getdeptListCom"
    ></selectdept>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import selectdept from '@/views/components/selectdept' //部门弹窗
import { allStoreList } from '@/api/system/store' //仓库
import { listSimpleQuery } from '@/api/system/dept' //部门
import {
  backMaterialListAPI,
  backMaterialUpdateStatusAPI
} from '@/api/produce/bill/backMaterial' //生产退料
export default {
  name: 'backMaterial',
  dicts: ['bill_status'],
  components: { bottomPage, cardTitleCom, seniorSearch, selectdept },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter (to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == '/index') {
        vm.queryParams.billStatus = to.query.billStatus
        vm.getList()
      }
    })
  },
  data () {
    return {
      //部门信息框
      opendept: false,
      //部门列表
      deptList: [],
      //自定义搜索框字段转换 (部门)
      normalizerdept: {
        Id: 'deptId',
        No: 'deptNo',
        Name: 'deptName'
      },
      storeData: [], //仓库数据
      loadingTable: false, //表格的加载
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      queryParams: {
        pageNum: 1,
        pageSize: 15
      } //查询
    }
  },

  created () {
    this.getInitializeData()
  },
  methods: {
    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //初始化下拉框
    async getInitializeData () {
      //生产退料单
      await this.getList()
      //部门
      const res1 = await listSimpleQuery()
      this.deptList = res1.data
      //获取仓库详情
      const res2 = await allStoreList()
      this.storeData = res2.data
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await backMaterialListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //选择部门带过来的信息
    getdeptListCom () {},
    //转换下拉框组件
    getDiyselectId (id, type) {
      this.queryParams[type] = id
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
    },
    //审核/删除/终止事件
    async auditBill (name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: '2',
            billType: '170205'
          }
          //发送审核api
          await backMaterialUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.billStatusTwoId.length === 0) {
            this.$message.error('勾选单据中没有已审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusTwoId,
            billStatus: '0',
            billType: '170205'
          }
          //发送反审核api
          await backMaterialUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('反审核成功')
        } else if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: '3',
            billType: '170205'
          }
          //发送删除api
          await backMaterialUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
        await this.getList()
      } catch {}
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.billId) //单据id
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map(item => {
          if (item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if (item.billStatus != '3' && item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await backMaterialListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'backMaterialDetail'
      })
    },
    //跳转详情
    handleDetail (row) {
      const billId = row.billId
      this.$router.push({
        name: 'backMaterialDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px 6px 10px;
  background-color: #eaeaea;
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  //单据表格
  .tableContent {
    margin: 6px 10px;
    .tableBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
    }
  }
}
</style>
