var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.backMaterialForm,
        "label-width": "96px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isImportOrderBtn: _vm.showImportBill,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: _vm.getImportOrder,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.backMaterialForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.backMaterialForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.backMaterialForm, "billNo", $$v)
                            },
                            expression: "backMaterialForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.backMaterialForm.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.backMaterialForm, "billDate", $$v)
                            },
                            expression: "backMaterialForm.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "退料工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled: _vm.disabled || _vm.isImportSource,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.backMaterialForm.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.backMaterialForm,
                                "produceDeptId",
                                $$v
                              )
                            },
                            expression: "backMaterialForm.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门", prop: "deptId" } },
                      [
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listSimpleQuery",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  showItem: [
                                    {
                                      deptId: _vm.backMaterialForm.deptId,
                                      deptName: _vm.backMaterialForm.deptName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.backMaterialForm.deptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.backMaterialForm, "deptId", $$v)
                            },
                            expression: "backMaterialForm.deptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "退料原因", prop: "backCause" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "退料原因",
                            size: "mini",
                          },
                          model: {
                            value: _vm.backMaterialForm.backCause,
                            callback: function ($$v) {
                              _vm.$set(_vm.backMaterialForm, "backCause", $$v)
                            },
                            expression: "backMaterialForm.backCause",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "备注",
                          prop: "billRemark",
                          "label-width": "60px",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                          },
                          model: {
                            value: _vm.backMaterialForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.backMaterialForm, "billRemark", $$v)
                            },
                            expression: "backMaterialForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "rightCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "marR10 x-f" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "marL10",
                        attrs: {
                          disabled:
                            _vm.disabled || !_vm.backMaterialForm.produceDeptId,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDistribution(1)
                          },
                        },
                      },
                      [_vm._v("批量修改仓库")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.backMaterialForm.details,
                          border: "",
                          "show-summary": "",
                          "summary-method": _vm.getSummaries,
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                          "selection-change": _vm.handleSelectionChange,
                          "row-click": _vm.handleRowClick,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "60",
                            align: "center",
                            selectable: function () {
                              return !_vm.disabled
                            },
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row("push", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row("del", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "商品编码",
                              width: "180",
                              align: "center",
                              prop: "goodsNo",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".goodsNo",
                                          rules: _vm.rules["details.goodsNo"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            option: _vm.optionGoods(scope.row),
                                          },
                                          model: {
                                            value: scope.row.goodsNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("商品编码")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            width: "130",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品类别",
                            align: "center",
                            prop: "categoryName",
                            width: "130",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "源单单号",
                            align: "center",
                            prop: "sourceBillNo",
                            width: "160",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "规格",
                              align: "center",
                              prop: "goodsSpec",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".goodsSpec",
                                          rules: _vm.rules["details.goodsSpec"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitPriceChange(
                                                  "goodsSpec",
                                                  scope.row,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.goodsSpec,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "goodsSpec",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.goodsSpec",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.goodsSpec,
                                                attrs: {
                                                  label: item.goodsSpec,
                                                  value: item.goodsSpec,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("规格")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitId",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitId",
                                          rules: _vm.rules["details.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitPriceChange(
                                                  "unitId",
                                                  scope.row,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "退料仓库",
                              align: "center",
                              prop: "inStoreId",
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".inStoreId",
                                          rules: _vm.rules["details.inStoreId"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          staticStyle: { width: "140px" },
                                          attrs: {
                                            disabled:
                                              _vm.disabled ||
                                              !_vm.backMaterialForm
                                                .produceDeptId,
                                            option: _vm.$select({
                                              key: "listProduceStore",
                                              option: {
                                                option: {
                                                  remoteBody: {
                                                    produceDeptId:
                                                      _vm.backMaterialForm
                                                        .produceDeptId,
                                                  },
                                                  disabled: _vm.disabled,
                                                  showItem: [
                                                    {
                                                      storeId:
                                                        scope.row.inStoreId,
                                                      produceStoreName:
                                                        scope.row.inStoreName,
                                                    },
                                                  ],
                                                },
                                              },
                                            }).option,
                                          },
                                          model: {
                                            value: scope.row.inStoreId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "inStoreId",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.inStoreId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("退料仓库")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "可退数量",
                            align: "center",
                            width: "120",
                            prop: "unitCanRefundQty",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "数量",
                              align: "center",
                              width: "120",
                              prop: "unitQty",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitQty",
                                          rules: _vm.rules["details.unitQty"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            type: "number",
                                            size: "mini",
                                            maxlength: "7",
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.unitQty,
                                                scope.row,
                                                "unitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            mousewheel: function ($event) {
                                              $event.preventDefault()
                                            },
                                            DOMMouseScroll: function ($event) {
                                              $event.preventDefault()
                                            },
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                            keyup: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "单价",
                            align: "center",
                            width: "130",
                            prop: "unitPrice",
                            formatter: function (v) {
                              return _vm.$syInput(2, v.unitPrice)
                            },
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "金额",
                            align: "center",
                            width: "150",
                            prop: "unitMoney",
                            formatter: function (v) {
                              return _vm.$syInput(3, v.unitMoney)
                            },
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            align: "center",
                            prop: "remark",
                            "min-width": "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                      maxlength: "80",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }