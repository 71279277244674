var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.options } },
        [
          _c("TablePage", {
            ref: "tablePage",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          }),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage1",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("NowPrintButton", {
        ref: "printRef",
        attrs: {
          isHome: true,
          isHiddenBtns: true,
          filters: _vm.nowPrintOptions,
          dialogType: "PrintTemplateNowPrintArr",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }