<template>
  <el-form ref="form" :model="backMaterialForm" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton :isImportOrderBtn="showImportBill" :disabled="disabled" :showAudit="showAudit"
        :isAddBtn="showAdd" @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" @getImportOrder="getImportOrder" id="topOperatingButton" />
      <!-- 基本信息盒子 -->
      <cardTitleCom cardTitle="基本信息" id="basicInformation" :billStatus="backMaterialForm.billStatus">
        <template slot="cardContent">
          <!-- 表格搜索条件 -->
          <!-- 第一行 -->
          <div class="x-w marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="backMaterialForm.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>

            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" size="mini" v-model="backMaterialForm.billDate" type="date"
                placeholder="单据日期" :disabled="disabled" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="退料工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="backMaterialForm.produceDeptId"
                style="width: 250px" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled || isImportSource,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="部门" prop="deptId">
              <SelectLocal v-model="backMaterialForm.deptId" style="width: 250px" :option="
                  $select({
                    key: 'listSimpleQuery',
                    option: {
                      option: {
                        disabled: disabled,
                        showItem: [
                          {
                            deptId: backMaterialForm.deptId,
                            deptName: backMaterialForm.deptName,
                          },
                        ],
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="退料原因" prop="backCause">
              <el-input class="inputWidth" :disabled="disabled" v-model="backMaterialForm.backCause" placeholder="退料原因"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark" label-width="60px">
              <el-input class="inputRemark" :disabled="disabled" v-model="backMaterialForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="rightCardTitle">
          <div class="marR10 x-f">
            <el-button :disabled="disabled || !backMaterialForm.produceDeptId" type="primary" size="mini"
              @click="getDistribution(1)" class="marL10">批量修改仓库</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table ref="multipleTable" style="width: 100%" :data="backMaterialForm.details" border show-summary
              :summary-method="getSummaries" @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave"
              :max-height="tableHeight" :height="tableHeight" @selection-change="handleSelectionChange"
              @row-click="handleRowClick" v-loading="loadingTable">
              <el-table-column type="selection" width="60" align="center" :selectable="() => !disabled" />
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click.stop="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"></i>
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click.stop="row('del', scope.$index)"
                    class="el-icon-remove operateDel"></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="180" align="center" prop="goodsNo">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>商品编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.goodsNo'" :rules="rules[`details.goodsNo`]">
                    <SelectRemote :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods(scope.row)" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName" width="130" />
              <el-table-column label="商品类别" align="center" prop="categoryName" width="130" />
              <el-table-column label="源单单号" align="center" prop="sourceBillNo" width="160">
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec" width="120"><template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>规格</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.goodsSpec'" :rules="rules[`details.goodsSpec`]">
                    <el-select v-model="scope.row.goodsSpec" :disabled="disabled" size="mini"
                      @change="unitPriceChange('goodsSpec', scope.row, $event)">
                      <el-option v-for="item in scope.row.units" :key="item.goodsSpec" :label="item.goodsSpec"
                        :value="item.goodsSpec" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitId" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.unitId'" :rules="rules[`details.unitId`]">
                    <el-select size="mini" v-model="scope.row.unitId" :disabled="disabled"
                      @change="unitPriceChange('unitId', scope.row, $event)">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="退料仓库" align="center" prop="inStoreId" min-width="160">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>退料仓库</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.inStoreId'" :rules="rules[`details.inStoreId`]">
                    <SelectRemote v-model="scope.row.inStoreId" style="width: 140px"
                      :disabled="disabled || !backMaterialForm.produceDeptId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: backMaterialForm.produceDeptId,
                              },
                              disabled: disabled,
                              showItem: [
                                {
                                  storeId: scope.row.inStoreId,
                                  produceStoreName: scope.row.inStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="可退数量" align="center" width="120" prop="unitCanRefundQty" />
              <el-table-column label="数量" align="center" width="120" prop="unitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.unitQty'" :rules="rules[`details.unitQty`]">
                    <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent @click.native.stop
                      @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitQty" :disabled="disabled"
                      type="number" size="mini" maxlength="7" @input="
                        limitInputlength(scope.row.unitQty, scope.row, 'unitQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="单价" align="center" width="130" prop="unitPrice"
                :formatter="(v) => $syInput(2, v.unitPrice)" />
              <el-table-column label="金额" align="center" width="150" prop="unitMoney"
                :formatter="(v) => $syInput(3, v.unitMoney)" />
              <el-table-column label="备注" align="center" prop="remark" min-width="160">
                <template slot-scope="scope">
                  <el-input @click.native.stop v-model="scope.row.remark" :disabled="disabled" size="mini"
                    maxlength="80"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  backMaterialAddAPI,
  backMaterialDetailAPI,
  backMaterialUpdateAPI,
  backMaterialUpdateStatusAPI,
} from "@/api/produce/bill/backMaterial"; //生产退料
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from "@/components/Dialog";
import { limitInputlength, fcount, isNumber } from "@/utils";
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
export default {
  name: "backMaterialDetail",
  dicts: ["App_is_use", "bill_status"],
  components: {
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
    Dialog,
  },
  data() {
    return {
      isImportSource: false, //是否引入源单
      //表格遮罩
      loadingTable: false,
      tableCellLabel: "序号",
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      // 表单参数
      backMaterialForm: {
        billDate: "", //单据日期
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{ goodsNo: "", hoverRow: false }],
      },
      //调出仓库id
      ids: [],
      //调出仓库多选
      multiple: true,
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "退料工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.inStoreId": [
          {
            required: true,
            message: "退料仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      //初始化数据
      await vm.getInitializeData();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type == "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await backMaterialDetailAPI(vm.$route.query.billId);
          // console.log('backMaterialForm', JSON.parse(JSON.stringify(data)))

          //筛选掉可退料为0的
          // data.details = data.details.filter(
          //   item => item.unitCanRefundQty && item.unitCanRefundQty > 0
          // )
          // if (data.details.length == 0) {
          //   data.details = [{}]
          // }
          //单据赋值
          vm.backMaterialForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.backMaterialForm));
        }
      } else if (vm.$route.query.type == "importBackMaterial") {
        await vm.getOrderBillNo();
        try {
          if (
            vm.$route.query.sourceGoodsList?.length > 0 &&
            !vm.$route.query.sourceGoodsList.find((x) => typeof x === "string")
          ) {
            //判断是否有以前新增时保存的数据
            vm.isImportSource = true;
            //缓存工厂
            vm.backMaterialForm.produceDeptId = Number(vm.$route.query.produceDeptId);
            if (vm.$route.query.sourceGoodsList) {
              vm.$set(vm.backMaterialForm, "details", vm.$route.query.sourceGoodsList);
              if (vm.backMaterialForm.details.length == 0) {
                vm.backMaterialForm.details = [{}];
              }
            }
            vm.oldForm = JSON.parse(JSON.stringify(vm.backMaterialForm));
          } else {
            //判断是否有以前新增时保存的数据
            if (JSON.stringify(vm.oldForm) != "{}") {
              vm.backMaterialForm = vm.oldForm;
            }
            vm.oldForm = JSON.parse(JSON.stringify(vm.backMaterialForm));
          }
        } catch (error) {
          //判断是否有以前新增时保存的数据
          if (JSON.stringify(vm.oldForm) != "{}") {
            vm.backMaterialForm = vm.oldForm;
          }
          vm.oldForm = JSON.parse(JSON.stringify(vm.backMaterialForm));
        }
      } else {
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.backMaterialForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.backMaterialForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.backMaterialForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.backMaterialForm));
    }
    next();
  },
  watch: {
    //侦听单据状态
    "backMaterialForm.billStatus": {
      handler(newVal) {
        if (this.backMaterialForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.backMaterialForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.backMaterialForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.backMaterialForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.backMaterialForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            billDetailItems: this.backMaterialForm.details,
            getMultipleGoods: this.getGoodS,
            row,
            goodsTypes: [2, 3]
          },
          that
        );
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.backMaterialForm.billStatus == "0" ||
        this.backMaterialForm.billStatus == ""
      ) {
        this.backMaterialForm.details = getCellMouseEnter(
          row,
          this.backMaterialForm.details
        );
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.backMaterialForm.details = getCellMouseLeave(this.backMaterialForm.details);
      this.tableCellLabel = "序号";
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.backMaterialForm.details.forEach((item) => {
        item.inStoreId = undefined;
      });
    },
    //打开弹窗
    getDistribution(val) {
      if (this.multiple) return this.$message.warning("请选择商品");
      this.dialogOptions.title = "选择仓库";
      this.dialogOptions.formData = this.$dialog({
        key: "produceStore",
        option: {
          table: {
            ...this.$dialog({ key: "produceStore" }).table,
            radioSelect: true,
            mutiSelect: false,
          },
        },
      });
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        produceDeptId: this.backMaterialForm.produceDeptId,
      };
      this.dialogOptions.show = true;
    },

    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.chooseOutStoreIdChange(
            this.dialogOptions.formData.table.check[0].storeId,
            this.dialogOptions.formData.table.check[0].produceStoreName
          );
          break;
        default:
          break;
      }
    },
    //批量修改仓库
    chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      try {
        this.loadingTable = true;
        const promises = this.backMaterialForm.details.map(async (item) => {
          if (this.ids.includes(item.goodsId)) {
            this.$set(item, "inStoreId", chooseOutStoreId);
            this.$set(item, "inStoreName", produceStoreName);
          }
        });
        Promise.all(promises).then(() => {
          this.loadingTable = false;
        });
      } catch (error) {
        this.loadingTable = false;
      }
    },
    //当前行
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (!this.disabled) {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = ["unitCanRefundQty", "unitQty", "unitMoney"];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.((x) => x.orderStoreId === orderStoreId)?.[key] ||
                0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/produce/bill/backMaterial" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      await this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.oldForm = JSON.parse(JSON.stringify(this.backMaterialForm));
      this.loading = false;
    },

    //多级单位换算
    unitPriceChange(name, row, value) {
      let obj = row.units.find((v) => v[name] == value);
      if (name == "goodsSpec") {
        this.$set(row, "unitId", obj.unitId);
      } else if (name == "unitId") {
        this.$set(row, "goodsSpec", obj.goodsSpec);
      }
      if (row.unitPrice && row.unitQty) {
        this.$set(row, "unitPrice", obj.unitPrice);
        let unitMoney = this.$syInput(3, fcount([row.unitQty, row.unitPrice], "*"));
        this.$set(row, "unitMoney", unitMoney);
      } else {
        this.$set(row, "unitMoney", 0);
      }
    },
    //数量
    onkeyupUnitQty(row) {
      if (row.unitCanRefundQty && row.unitCanRefundQty < row.unitQty) {
        this.$set(row, "unitQty", undefined);
        this.$set(row, "unitMoney", 0);
        this.$message.error("退料数量不能大于可退数量！");
        return;
      }

      if (row.unitPrice && row.unitQty) {
        let unitMoney = row.unitQty * row.unitPrice;
        unitMoney = this.formatNumber(unitMoney);
        this.$set(row, "unitMoney", unitMoney);
      } else {
        this.$set(row, "unitMoney", 0);
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.backMaterialForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.backMaterialForm.billId],
            billStatus: "2",
            billType: "170205",
          };
          //发送审核api
          const { data } = await backMaterialUpdateStatusAPI(obj);
          this.backMaterialForm = data;
          // 弹出提示
          // this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.backMaterialForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.backMaterialForm.billId],
            billStatus: "0",
            billType: "170205",
          };
          //发送反审核api
          const { data } = await backMaterialUpdateStatusAPI(obj);
          this.backMaterialForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.backMaterialForm.billId) {
            const res = await backMaterialUpdateAPI(this.backMaterialForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.backMaterialForm = res.data;
              this.$modal.msgSuccess(audit ? "审核成功" : "修改单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await backMaterialAddAPI(this.backMaterialForm);

            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.backMaterialForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "新增单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },
    //单据编号
    async getOrderBillNo() {
      this.backMaterialForm.billNo = await getBillNo(170205);
    },

    //引入源单
    getImportOrder() {
      if (!this.backMaterialForm.produceDeptId)
        return this.$message.error("退料工厂不能为空");
      this.$router.push({
        name: "backMaterialImport",
        query: {
          produceDeptId: this.backMaterialForm?.produceDeptId,
          type: "backMaterialImport",
        },
      });
    },
    //选中的商品
    getGoodS(rows) {
      rows.forEach((v) => {
        this.$set(v, "unitQty", 0);
        this.$set(v, "unitPrice", v.purPrice);
        this.$set(v, "unitMoney", v.unitQty * v.unitPrice);
        v.hoverRow = false;
        v.remark = undefined;
      });
      this.backMaterialForm.details = getSelectAssign(
        rows,
        this.backMaterialForm.details
      );
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.backMaterialForm.details.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.backMaterialForm.details.length <= 1) {
          this.backMaterialForm.details = [{
            goodsNo: "",
            hoverRow: false,
          }];
        } else {
          this.backMaterialForm.details.splice(index, 1);
        }
      }
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //获取单据日期
      this.backMaterialForm.billDate = getNewDate();
      const res = await produceDeptList({ produceDeptNo: "0000" });
      if (res.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(this.backMaterialForm, "produceDeptId", res.rows[0]?.produceDeptId);
        this.$set(this.backMaterialForm, "produceDeptName", res.rows[0]?.produceDeptName);
        // this.changeProduceGroupChange(res.rows[0]?.produceDeptId)
      }

      this.loading = false;
    },
    // 表单重置
    reset() {
      this.isImportSource = false; //是否引入源单
      //新增按钮
      this.showAdd = false;
      //保存禁用
      this.disabled = false;
      this.backMaterialForm = {
        billDate: "", //单据日期
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{ goodsNo: "", hoverRow: false }],
      };
      this.resetForm("form");
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
