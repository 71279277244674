<template>
  <el-form ref="form" label-width="70px">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        @submitForm="submitForm"
        @getQuit="getQuit"
        id="topOperatingButton"
      />
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <div class="marB10">
              <span class="fS14MR10">单据类型</span>
              <el-checkbox
                v-model="isProduce"
                class="checkOrder"
                :disabled="true"
                >生产领料单</el-checkbox
              >
            </div>
            <!-- 高级搜索按钮 -->
            <seniorSearch
              filterTimeStr="thisMonth"
              v-model="queryParams"
              :getList="getList"
              :isInvokeList="false"
              @isShowHighCom="getIsShowHigh"
              :refreshSearch="refreshQueryParams"
              :class="{ marB10: !showHigh }"
            />
            <!-- 第二行 -->
            <div v-show="showHigh" class="x-w marT10">
              <el-form-item label="单据编号" prop="billNo">
                <el-input
                  class="inputWidth"
                  placeholder="全部"
                  v-model="queryParams.billNo"
                  size="mini"
                />
              </el-form-item>
              <el-form-item label="领料仓库" prop="outStoreIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.outStoreIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceStore',
                      option: {
                        option: {
                          remoteBody: {
                            produceDeptId: queryParams.produceDeptId
                          }
                        }
                      }
                    }).option
                  "
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="选择源单">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              v-loading="loadingTable"
              ref="multipleTable"
              tooltip-effect="dark"
              :data="tableData"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick"
              border
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="80"
                type="index"
              />
              <el-table-column
                show-overflow-tooltip
                label="单据编号"
                align="center"
                prop="billNo"
                min-width="180"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    @click="handleDetail(scope.row)"
                    target="_blank"
                  >
                    {{ scope.row.billNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="单据日期"
                align="center"
                prop="billDate"
                min-width="110"
              />
              <el-table-column
                label="生产计划单号"
                align="center"
                prop="sourceBillNo"
                min-width="180"
              />
              <el-table-column
                label="生产部门"
                align="center"
                prop="deptName"
                min-width="136"
              />
              <el-table-column
                label="领料仓库"
                align="center"
                prop="outStoreName"
                min-width="126"
              />
              <el-table-column
                label="备注"
                align="center"
                prop="billRemark"
                min-width="155"
              />
              <el-table-column
                label="创建人"
                align="center"
                prop="createBy"
                min-width="120"
              />
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                min-width="155"
              />
              <el-table-column
                label="审核人"
                align="center"
                prop="auditBy"
                min-width="120"
              />
              <el-table-column
                label="审核时间"
                align="center"
                prop="auditTime"
                min-width="155"
              />
              <el-table-column
                label="修改人"
                align="center"
                prop="updateBy"
                min-width="120"
              />
              <el-table-column
                label="修改时间"
                align="center"
                prop="updateTime"
                min-width="155"
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="ids"
              :totalCom="total"
              @selectAllCom="selectAll"
            />
          </div>
        </template>
      </cardTitleCom>
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  leadMaterialListAPI,
  findDetailGoodsInfoAPI
} from '@/api/produce/bill/leadMaterial' //生产退料
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'backMaterialImport',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    topOperatingButton,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      //单据类型
      isProduce: true,
      loading: false,
      loadingTable: false,
      showHigh: false, //是否显示高级搜索
      ids: [], // 选中数组
      total: 0, // 总条数
      storeData: [], //仓库数据
      employeeData: [], //制单人框
      tableData: [], //表格数据
      //重置查询参数
      refreshQueryParams: {
        pageNum: 1,
        pageSize: 15,
        isLimitationRefundedQty: true,
        billTypeInfo: 170205
      },
      //查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        isLimitationRefundedQty: true,
        billTypeInfo: 170205
      }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      topOperatingH: 0, //顶部按钮高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return (
        this.vivwH - this.topOperatingH - this.searchH - this.paginationH - 62
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      // 访问组件实例 vm 中的数据和方法
      if (
        vm.$route.query.type === 'backMaterialImport' &&
        vm.$route.query?.produceDeptId
      ) {
        vm.refreshQueryParams.produceDeptId = vm.$route.query.produceDeptId
        vm.queryParams.produceDeptId = vm.refreshQueryParams.produceDeptId
        await vm.getList()
      }
    })
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await leadMaterialListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.billId) //单据id
    },
    //确定
    async submitForm () {
      if (this.ids.length > 0) {
        let { rows } = await findDetailGoodsInfoAPI({
          billIds: this.ids,
          billType: '170205'
        })
        rows.forEach(v => {
          this.$set(v, 'inStoreId', v.outStoreId)
          this.$set(v, 'inStoreName', v.outStoreName)
          this.$set(v, 'unitPrice', v.unitPurPrice)
          this.$set(v, 'billDetailId', undefined)
          this.$set(v, 'sourceBillNo', v.billNo)
          this.$set(v, 'unitQty', v.unitCanRefundQty)
          this.$set(v, 'sourceBillId', v.billId)
        })

        rows = rows.filter(
          item => item.unitCanRefundQty && item.unitCanRefundQty > 0
        )

        this.$store.state.tagsView.visitedViews.splice(
          this.$store.state.tagsView.visitedViews.findIndex(
            item => item.path === this.$route.path
          ),
          1
        )
        this.$destroy()
        this.$router.push({
          name: 'backMaterialDetail',
          query: {
            sourceGoodsList: rows,
            produceDeptId: this.queryParams.produceDeptId,
            type: 'importBackMaterial'
          }
        })
      } else {
        this.$store.state.tagsView.visitedViews.splice(
          this.$store.state.tagsView.visitedViews.findIndex(
            item => item.path === this.$route.path
          ),
          1
        )
        this.$destroy()
        this.$router.push({
          name: 'backMaterialDetail'
        })
      }
    },
    //取消
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/produce/bill/backMaterial/backMaterialDetail')
      this.$destroy()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await leadMaterialListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
    //筛选单据
    .checkOrder {
      margin-right: 50px;
    }
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
::v-deep .el-form-item {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
