<template>
  <div class="wrap">
    <el-form :model="options">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </el-form>
    <Dialog ref="tablePage1" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    <NowPrintButton :isHome='true' :isHiddenBtns='true' :filters="nowPrintOptions" ref="printRef" dialogType='PrintTemplateNowPrintArr'/>
  </div>
</template>

<script>
import {
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { fcount, deepCopy } from '@/utils'
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listProduceBillGoodsAuto,
  calculateDistribute,
  completeDistribute,
  listCalculateDistributeDetail,
  addSmartDistributeGoods,
  delSmartDistributeGoods,
  modifySmartDistributeGoodsInfo
} from "@/api/produce/bill/autoDistribute"; //智能分货
export default {
  name: "AutoDistribute",
  components: {
    NowPrintButton: () =>
      import("@/components/tablePage/tableButtons/nowPrint.vue"), Dialog, TablePage, SelectRemote
  },
  data() {
    return {
      delArr: [],//缓存删除
      nowPrintOptions: {
        detail: deepCopy({
          ...this.distributeStoreForm,
          detailItem: [],
        }
        ),
        isArrery: true,
      },
      dialogOptions: {
        title: "修改实配数",
        width: 1250,
        show: false,
        type: "FactDistribute",
        billDetailId: undefined,
      },
      options: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 100,
        },
        defaultBody: {
          // billIds: this.$route.query.type == "inProduceStore" ? JSON.parse(this.$route.query.billIds) : null
        },
        getListApi: listProduceBillGoodsAuto,
        listNo: true, // 序号
        tableCellLabel: '序号',
        isplusMinusSign: false, // 显示加减
        check: [], // 选中数据
        rowKey: "lineId",
        //搜索
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginCreateDate: a, endCreateDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: this.$route.query.type == "inProduceStore" ? '' : "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "filters",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          // { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "submit",
            label: "保存",
            type: "primary",
            noRule: true,
          },
          {
            click: "calculate",
            label: "分货计算",
            type: "primary",
            alertText: "确认要进行分货计算吗？",
          },
          {
            click: "update",
            label: "修改实配数",
            type: "primary",
          },
          {
            click: "accomplish",
            label: "配货完成",
            type: "primary",
            alertText: "确认要进行配货完成吗？",
          },
          {
            click: "examine",
            label: "配货查看",
            type: "primary",
            // noRule: true,
          },
          {
            click: "importOrder",
            label: "引入源单",
            type: "primary",
            noRule: true,
          },
          {
            click: "print",
            label: "打印",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除所选商品吗？",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          // {
          //   prop: "billNo",
          //   label: "单据编号",
          //   // type: "link",
          //   // click: "update",
          //   minWidth: 150,
          //   align: "center",
          // },
          {
            prop: "produceDeptId",
            label: "生产工厂",
            showItem: {
              optionId: "produceDeptId",
              optionName: "produceDeptName",
              prop: 'produceDeptId',
              label: 'produceDeptName',
            },
            type: 'remoteSelect',
            option: this.$select({
              key: "listProduceDept",
            }).option,
            disabled: (v, row) => row?.sourceBillNo != null || row?.autoDistributeStatus == 3,
            minWidth: 160,
            align: "center"
          },
          {
            prop: "goodsId",
            label: "商品编号",
            showItem: {
              optionId: "goodsId",
              optionName: "goodsName",
              prop: 'goodsId',
              label: 'goodsNo',
            },
            type: 'remoteSelect',
            option: this.$select({
              key: "listGoods",
            }).option,
            disabled: (v, row) => row?.sourceBillNo != null || row?.autoDistributeStatus == 3,
            minWidth: 180,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "batchNo",
            label: "批号",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "outStoreId",
            label: "出库仓库",
            showItem: {
              optionId: "storeId",
              optionName: "produceStoreName",
              prop: 'outStoreId',
              label: 'outStoreName',
            },
            type: 'remoteSelect',
            option: this.$select({
              key: "listProduceStore",
            }).option,
            disabled: (v, row) => row?.sourceBillNo != null || row?.autoDistributeStatus == 3,
            minWidth: 160,
            align: "center"
          },
          {
            prop: "unitEligibleQty",
            label: "入库合格数",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitQty",
            label: "分货数",
            minWidth: 100,
            align: "center",
            type: 'my-input',
            inputType:'number',
            disabled: (v, row) => row?.autoDistributeStatus == 2 || row?.autoDistributeStatus == 3,
          },
          {
            prop: "autoDistributeStatus",
            label: "配货状态",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => {
              return propValue == 1 ? "未计算" : propValue == 2 ? "已计算" : propValue == 3 ? "已完成" : "未知";
            },
          },
          {
            prop: "sourceBillNo",
            label: "入库单号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "sourceBillDate",
            label: "入库单日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "distributeBillNo",
            label: "分货单号",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "distributeBillDate",
            label: "分货单日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "auditBy",
            label: "完成人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "完成时间",
            minWidth: 155,
          }
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      console.log('aaa',type,row);
      let typeKeys = []
      switch (type) {
        case "calculate":
          //分货计算
          this.options.check.forEach((item) => {
            if (item.autoDistributeStatus == 1) {
              typeKeys.push(
                {
                  lineId: item.lineId,
                }
              )
            }
          });
          if (typeKeys.length == 0) return this.$message.error("请选择未计算的单据！")
          await calculateDistribute(typeKeys)
          this.$message.success("计算成功！");
          await this.$refs.tablePage.getList();
          break;
        case "accomplish":
          //配货完成
          try {
            this.options.loading = true
            this.options.check.forEach((item) => {
              if (item.autoDistributeStatus == 2) {
                typeKeys.push(
                  {
                    lineId: item.lineId,
                  }
                )
              }
            });
            if (typeKeys.length == 0) return this.$message.error("请选择已计算的单据！")
            await completeDistribute(typeKeys)
            this.$message.success("配货完成！");
            await this.$refs.tablePage.getList();
          } finally {
            this.options.loading = false
          }
          break;
        case "examine":
          //配货查看
          if (this.options?.check.length > 1) {
            return this.$message.error("只能选择一个单据进行配货查看！");
          }
          this.$router.push({
            name: "AutoDistributeDetail",
            query: {
              lineId: this.options?.check[0]?.lineId,
              type: "Update",
            },
          });
          break;
        case "update":
          if (this.options?.check.length > 1) {
            return this.$message.error("只能选择一个单据进行修改实配数！");
          }
          this.options.check.forEach((item) => {
            if (item.autoDistributeStatus == 2) {
              typeKeys.push(
                {
                  billId: item.billId,
                }
              )
            }
          });
          if (typeKeys.length == 0) return this.$message.error("请选择已计算未配货完成的单据！")
          this.dialogOptions = {
            title: "修改实配数",
            width: 1250,
            show: false,
            type: "FactDistribute",
            lineId: undefined,
          },
            this.dialogOptions.lineId = this.options?.check[0]?.lineId;
          this.dialogOptions.show = true;
          break;
        case "print":
          // 假设 this.options.check 是一个包含选中单据的对象数组
          // 每个对象至少有一个 lineId 属性

          if (this.options?.check.length === 0) {
            // 如果没有选择任何单据，可以显示一个消息或进行其他处理
            return this.$message.error("请选择要打印的单据！");
          }

          // 将所有选中的 lineIds 映射到一个数组中
          const lineIds = this.options?.check.map(item => {
            return item.lineId
          });

          // 调用新的接口获取多个单据的详细信息
          try {
            const res = await listCalculateDistributeDetail({ lineIds });

            // 假设 res.data 是一个包含所有单据详细信息的数组
            // 初始化一个数组来存储处理后的数据
            let arrForAllBills = [];
            let shopItem = []
            for (let i = 0; i < res.data.length; i++) {
              const k = res.data[i];
              k.calculateDetails.forEach(item => {
                shopItem.push({
                  shopNo: item.clientNo,
                  shopName: item.clientName,
                  shopId: item.clientId,
                  deliverLineId: item.deliverLineId,
                })
              })
            }
            let uniqueShopIds = new Set();
            shopItem = shopItem.filter(item => {
              if (!uniqueShopIds.has(item.shopId)) {
                uniqueShopIds.add(item.shopId);
                return true;
              }
              return false;
            });
            // 遍历每个单据的详细信息
            res.data.forEach(billDetail => {
              let arr = []; // 为当前单据初始化一个数组来存储处理后的数据
              let shopSome = billDetail.calculateDetails.map(item => ({
                shopNo: item.clientNo,
                shopName: item.clientName,
                shopId: item.clientId,
                deliverLineId: item.deliverLineId,
              }));
              // 遍历当前单据的每条配送线路
              billDetail.deliverLines.forEach(line => {
                let obj = {
                  lineName: line.lineName,
                  lineId: line.lineId,
                  goodsNo: billDetail.goodsInfo.goodsNo, // 假设 goodsInfo 是在 billDetail 上
                  goodsName: billDetail.goodsInfo.goodsName, // 假设 goodsInfo 是在 billDetail 上
                  shopItem: deepCopy(shopItem), // 复制当前单据的 shopItem
                  shopSome
                };

                // 查找与当前配送线路相关的店铺信息
                let shops = billDetail.calculateDetails.filter(y => y.deliverLineId === line.lineId);

                // 遍历 shopItem 并填充 value 属性
                obj.shopItem.forEach(k => {
                  let obj2 = shops.find(x => x.clientId === k.shopId);
                  if (obj2) {
                    k.value = obj2.factDistributeQty;
                  }
                });

                // 计算总数量
                obj.total = obj.shopItem.reduce((accumulator, currentItem) => {
                  return fcount([accumulator, (currentItem.value || 0)], "+"); // 累加值，处理不存在或假值的情况
                }, 0);

                // 将处理后的对象添加到当前单据的数组中
                arr.push(obj);
              });

              // 将当前单据的数组添加到汇总数组中
              arrForAllBills.push(...arr);
            });

            // 将处理后的数据保存到组件的 data 中
            console.log('arrForAllBills', arrForAllBills);
            this.nowPrintOptions.detail = deepCopy({ detailItem: arrForAllBills }); // 使用 detailItems 来表示多个单据的详情

            // 等待 DOM 更新后调用打印方法
            await this.$nextTick();
            this.$refs.printRef.nowPrint();
          } catch (error) {
            // 处理任何可能发生的错误
            console.error('获取多个单据信息时发生错误:', error);
            this.$message.error("获取单据信息失败！");
          }
          break;
        case "importOrder":
          this.dialogOptions.type = "TreeAndTable";
          this.dialogOptions.title = "引入源单";
          this.dialogOptions.formData = this.$dialog({
            key: "autoDistributeImport",
          });
          this.dialogOptions.show = true;
          break;
        case "dialogChange":
          let arrery = this.dialogOptions.formData.table.check.map(item => {
            return {
              produceDeptId: item.produceDeptId,
              outStoreId: item.inStoreId,
              sourceBillId: item.billId,
              sourceBillDetailId: item.billDetailId,
              sourceBillType: item.billType,
              sourceBillNo: item.billNo,
              batchNo: item.batchNo,
              goodsId: item.goodsId,
              unitId: item.unitId,
              unitQty: item.unitQty,
              basUnitQty: item.basUnitQty,
              remark: item.remark,
            }
          })
          await addSmartDistributeGoods(arrery)
          this.$message.success("添加成功！")
          await this.$refs.tablePage.getList();
          break;
        case "del":
          let arr = this.options?.check.map(item => {
            return {
              lineId: item.lineId,
            }
          });
          await delSmartDistributeGoods(arr)
          this.$message.success("删除成功！")
          await this.$refs.tablePage.getList();
          break;
        case "tableRowChange":
          if (row.name === "push") {
            this.options.list.splice(row.index + 1, 0, {
              hoverRow: false,
            });
          } else {
            if (row?.autoDistributeStatus == 3) {
              return this.$message.error("已完成的商品不允许删除！");
            }
            if (this.options.list.length <= 1) {
              this.options.list = [{
                hoverRow: false,
              }];
            } else {
              this.options.list.splice(row.index, 1);
              if (row.lineId) {
                this.delArr.push({
                  lineId: row.lineId,
                });
              }
            }
          }
          break;
        case "submit":
          let addArr = this.options?.list.map(item => {
            if (!item.lineId) {
              return {
                produceDeptId: item.produceDeptId,
                outStoreId: item.outStoreId,
                unitQty: item.unitQty,
                goodsId: item.goodsId,
                unitId: 0,
                batchNo: item.batchNo,
              }
            }
          }).filter(v => v != undefined)
          let updateArr = this.options?.list.map(item => {
            if (item.lineId) {
              return {
                lineId: item.lineId,
                produceDeptId: item.produceDeptId,
                outStoreId: item.outStoreId,
                unitQty: item.unitQty
              }
            }
          }).filter(v => v != undefined)
          if (addArr.length > 0) {
            await addSmartDistributeGoods(addArr)
          }
          if (updateArr.length > 0) {
            await modifySmartDistributeGoodsInfo(updateArr)
          }
          if (this.delArr.length > 0) {
            await delSmartDistributeGoods(this.delArr)
            this.delArr = []
          }
          await this.$refs.tablePage.getList();
          this.$message.success("保存成功！")
          break;
        case "cellMouseEnter":
          this.options.list = getCellMouseEnter(
            row,
            this.options.list
          );
          this.options.tableCellLabel = "操作";
          this.options.isplusMinusSign = true;
          break;
        case "cellMouseLeave":
          this.options.list = getCellMouseLeave(this.options.list);
          this.options.tableCellLabel = "序号";
          this.options.isplusMinusSign = true;
          break;
        case "getListed":
          if (this.options.list.length==0) {
            this.options.list = [{}]
          }
          break; 
        default:
          break;
      }
    },
    saveModifiedItems(originalArray, possiblyModifiedArray) {
      // 创建一个空数组来保存修改的对象
      const modifiedItems = [];

      // 遍历可能已修改的数组
      possiblyModifiedArray.forEach((modifiedItem, index) => {
        // 获取原始数组中的对应项
        const originalItem = originalArray[index];

        // 检查对象是否已修改（这里只检查顶层属性，不检查嵌套对象）
        if (this.isObjectModified(originalItem, modifiedItem)) {
          // 将修改的对象添加到modifiedItems数组中
          modifiedItems.push(modifiedItem);
        }
      });
      // 返回修改的对象数组（如果需要的话）
      return modifiedItems;
    },

    isObjectModified(original, modified) {
      // 假设我们只关心对象的顶层属性
      // 使用JSON.stringify来比较两个对象是否相同（注意这不适用于嵌套对象和函数）
      return JSON.stringify(original) !== JSON.stringify(modified);
    },
  },
};
</script>
